<template>
    <div class="list-page page">

        <div class="page--list" style="background:#fff;">
            <div class="pl16 pr16">
                <div class="d_title flex a-center" >基础信息
                    <el-button style="margin-left:auto;" type="primary" @click.stop="handleClose">返回</el-button>
                </div>
            </div>
            <div class="flex pt24 pb24 pl16" >
                <div class="d_item">员工编号：<span class="d_inf">{{empInfo.usercode}}</span></div>
                <div class="d_item">员工姓名：<span class="d_inf">{{empInfo.empName}}</span></div>
                <div class="d_item">岗位：<span class="d_inf">{{empInfo.postName}}</span></div>
            </div>
            <div class="pl16 pr16">
                <div class="d_title">移民绩效单</div>
            </div>
            <div class=" pd4 set--bottom-foot-padding" style="padding-bottom:24px;">
                <el-table :data="aymMeritsCalculateList">
                    <el-table-column label="签约单编号" prop="contractNo" ></el-table-column>
                    <el-table-column label="签约区域" prop="orgName"></el-table-column>
                    <el-table-column label="签约项目" prop="contractTypeName"></el-table-column>
                    <el-table-column label="签约日期" prop="contractTimeName"></el-table-column>
                    <el-table-column label="签约员工" prop="empName"></el-table-column>
                    <el-table-column label="销售主管" prop="managerEmpName"></el-table-column>
                    <el-table-column label="销售经理" prop="directorEmpName"></el-table-column>
                    <el-table-column label="绩效核算月份" prop="calculateTimeName"></el-table-column>
                    <el-table-column label="绩效单数" prop="calculateNum"></el-table-column>
                </el-table>
            </div>
            <div class="pl16 pr16">
                <div class="d_title">债券绩效单</div>
            </div>
            <div class=" pd4 set--bottom-foot-padding">
                <el-table :data="monMeritsCalculateList">
                    <el-table-column label="订单编号" prop="contractNo" ></el-table-column>
                    <el-table-column label="签约区域" prop="orgName"></el-table-column>
                    <el-table-column label="签约日期" prop="contractTimeName"></el-table-column>
                    <el-table-column label="认购额度" prop="contractMoney"></el-table-column>
                    <el-table-column label="签约员工" prop="empName"></el-table-column>
                    <el-table-column label="销售主管" prop="managerEmpName"></el-table-column>
                    <el-table-column label="销售经理" prop="directorEmpName"></el-table-column>
                    <el-table-column label="绩效核算月份" prop="calculateTimeName"></el-table-column>
                    <el-table-column label="绩效单数" prop="calculateNum"></el-table-column>
                </el-table>
            </div>
        </div>

       
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            meritsId:'',
            searchType:'1',// 1员工绩效详情 2团队绩效详情
            aymMeritsCalculateList:[], 
            monMeritsCalculateList:[],
            empInfo:'',
            searchTime:'',
        };
    },
    created() {
        console.log(this.$route);
        this.meritsId = this.$route.params.id
        this.searchType = this.$route.params.type
        this.searchTime = this.$route.params.searchTime
        this.fetchData();
    },
    mounted() {},
    watch: {},
    computed: {},
    methods: {
        async fetchData() {
            const loading = this.$loading();
            const res = await this.$axios({
                url: "/api/empMerits/queryEmpMeritsCalculateList",
                method: "post",
                data: {
                   meritsId:this.meritsId,
                    searchType:this.searchType,
                    searchTime:this.searchTime
                },
            });
            loading.close();
            if (res.code === 2000) { 
                this.empInfo  =res.data.empInfo
                this.aymMeritsCalculateList = res.data.aymMeritsCalculateList || []; //移民绩效数据
                this.monMeritsCalculateList = res.data.monMeritsCalculateList || []; //债券绩效数据
            } else {
                this.$message({
                    message: res.msg || "获取数据失败",
                    type: "warning",
                });
            }
        },
        handleClose() {
            this.$router.go(-1);
        },
   
    },
};
</script>
<style lang="scss" scoped>

.d_title{
    color: #303133;
    font-weight: 600;
    height: 64px;
    line-height: 64px;
    border-bottom: 1px solid #EBEDF0;

}
.d_item{
    color: rgba(0,0,0,0.85);
    width: 33%;
}
.d_inf{
    color: rgba(0,0,0,0.65);
}
.header {
    height: 40px;
}
// 公用
.pl16 {
    padding-left: 16px;
}
.pr16 {
    padding-right: 16px;
}
.pt24 {
    padding-top: 24px;
}
.pb24 {
    padding-bottom: 24px;
}
.ml12 {
    margin-left: 12px;
}
.flex {
    display: flex;
}
.flex-column {
    flex-direction: column;
}
.flex1 {
    flex: 1;
}
.a-center {
    align-items: center;
}
.fb {
    font-weight: 600;
}
.f16 {
    font-size: 16px;
}
.hover {
    cursor: pointer;
}
.w_50{
    width: 50%;
}
.el-icon-devops {
    width: 12px;
    height: 12px;
    background: url('../../assets/images/export.png') center no-repeat;
    font-size: 12px;
    background-size: cover;
    margin-right: 5px;
}

// 公用完
</style>